.Feedback {
  width: 100%;
  font-family: "Mada", sans-serif;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: url(../../assets/svg/bg-bottom-left.svg),
    url(../../assets/svg/bg-top-right.svg),
    linear-gradient(190deg, #009878 30.73%, #26a896 100%);
  background-position: bottom left, top right;
  background-repeat: no-repeat;

  .feedback-content {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border: 1px solid #bbb;
    padding: 1.5rem;
    border-radius: 5px;
    min-height: 525px;
    width: 700px;
    text-align: center;
    box-shadow: 0 0 3em #666060;

    hr {
      margin-top: 1rem;
      margin-bottom: 1rem;
      width: 100%;
      border: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    img {
      height: 7rem;
      margin: 1rem 0;
    }

    .feedback-title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.4em;
    }

    .feedback-body {
      padding-top: 1rem;

      a {
        color: #009878;
      }
    }
  }
}
