.ConditionsGeneralesUtilisation {
  height: 100%;
  font-size: 16px;
  p {
    text-align: justify;
  }

  ul {
    list-style: inside;
  }
}