.CheckboxField {
  input[type="checkbox"]:after {
    -moz-appearance: initial;
    content: " ";
    height: 25px;
    width: 25px;
    background-color: #ffffff;
    display: inline-block;
    visibility: visible;
    border: 1px solid #9e9e9e;
    position: inherit;
    top: -1px;
  }

  input[type="checkbox"]:checked:after {
    background-image: url("../../../assets/svg/check-blanche-bk-vert.svg");
    border: none;
    background-repeat: no-repeat;
    background-size: 100%;
    -moz-appearance: initial;
  }
}
