.CHeaderForm {
    
  .logo-title {
    color: #009878;
    font-size: 1.5em;
    margin-left: 5px;
  }

  @media screen and (max-height: 930px) {
    .header-logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #ffffff;

      a {
        width: auto;
        flex: auto;
        img {
          height: 10vh;
          padding: 3px 0;
          //width: 100%;
          image-rendering: -moz-crisp-edges; /* Firefox */
          image-rendering: -o-crisp-edges; /* Opera */
          image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
          image-rendering: crisp-edges;
          -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
        }
      }

      a:last-child {
        padding-right: 26px;
      }

      h4 {
        padding-left: 0;
      }
    }
  }

  @media screen and (min-height: 931px) {
    .header-logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #ffffff;

      a {
        width: auto;
        flex: auto;
        img {
          height: 5vh;
          padding: 3px 0;
          //width: 100%;
          image-rendering: -moz-crisp-edges; /* Firefox */
          image-rendering: -o-crisp-edges; /* Opera */
          image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
          image-rendering: crisp-edges;
          -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
        }
      }

      a:last-child {
        padding-right: 26px;
      }

      h4 {
        padding-left: 0;
      }
    }
  }
}
