.signup {
  display: flex;
  font-family: "Mada", sans-serif;
  flex-direction: column;
  align-items: stretch;
  padding: 2rem;

  background: url(../../assets/svg/bg-bottom-left.svg),
    url(../../assets/svg/bg-top-right.svg),
    linear-gradient(190deg, #009878 30.73%, #26a896 100%);
  background-position: bottom left, top right;
  background-repeat: no-repeat;
  height: 90%;

  overflow-y: auto;
  scrollbar-color: #009878 #d7d7cd;

  &::-webkit-scrollbar {
    height: 13px;
    width: 13px;
    background: #d7d7cd;
  }

  &::-webkit-scrollbar-thumb {
    background: #009878;
    border: 3px solid #009878;
  }

  &::-webkit-scrollbar-corner {
    background: #d7d7cd;
  }

  a {
    color: #009878;
  }

  .signup-form {
    height: auto;
    margin: auto 0;
    display: flex;
    justify-content: center;
    align-items: center;

    form {
      border: 1px solid #d7d7cd;
      padding: 2rem 2rem;
      background-color: #fff !important;
      width: 700px;
      border-radius: 5px;
      box-shadow: 0 0 0.8em #666060;

      .form-label {
        font-weight: 500;
      }

      .logo {
        text-align: center;
        img {
          height: 7rem;
          margin: 1rem 0;
        }
      }

      .title {
        text-align: center;
        font-size: 1.4em;
        font-weight: bold;
        margin: 0.6em 0;
        text-transform: uppercase;
      }

      .title-header {
        color: #009878;
        text-transform: uppercase;
      }
      .controls {
        margin: 2em 0;
        padding: 0 2em;
        width: 100%;
      }

      .actions {
        text-align: center;

        .btn {
          padding: 0.5rem 1rem 0.5rem 1rem;
          background-color: #009878 !important;
          border: 2px solid #009878 !important;
          color: #ffffff;
          font-weight: 600;
          width: 30%;
          &:hover {
            background-color: #3ba39b !important;
          }
        }
      }

      .invalid-feedback {
        display: inline;
      }
    }
  }

  input[type="checkbox"] + label {
    margin-left: 1.6rem;
    font-weight: 600;
    font-size: 14px;
  }

  .form-check {
    position: relative;
    padding-left: 1.25rem;
    margin: auto;
    display: flex;
    align-items: center;
  }

  input[type="checkbox"] {
    -moz-appearance: initial;
  }

  input[type="checkbox"]:after {
    -moz-appearance: initial;
    content: " ";
    height: 30px;
    width: 30px;
    background-color: #ffffff;
    display: inline-block;
    visibility: visible;
    border: 2px solid #c1c1c1;
    position: inherit;
    margin-top: -10px;
    border-radius: 2px;
  }

  input[type="checkbox"]:checked:after {
    background-image: url("../../assets/svg/check-verte-bk-transp.svg");
    border: none;
    //color: #ffffff;
    position: inherit;
    background-repeat: no-repeat;
    -moz-appearance: initial;
    height: 30px;
    width: 30px;
    margin-top: -10px;
  }
}

@media screen and (max-height: 930px) {
  .signup {
    height: 90%;
  }
}

@media screen and (min-height: 931px) {
  .signup {
    height: 95%;
  }
}

.signup-feedback {
  height: 90%;
  .text-success {
    color: #009878 !important;
    font-weight: bold;
  }
  .Feedback .feedback-body img {
    border-bottom: 0px;
    margin: 0;
  }
}

@-moz-document url-prefix() {
  .signup {

    input[type="checkbox"] {
      margin-top: 11px;
    }

    input[type="checkbox"]:after {
      margin-top: -18px;
      border-radius: 2px;
    }

    input[type="checkbox"]:checked:after {
      margin-top: -20px;
    }
  }
}

.cgu-modal {
  z-index: 2147483649 !important;

  .modal-body {
    overflow-y: hidden;
  }

  .modal-content {
    height: 82vh;
    max-height: 82vh;
  }

  .CGUContent {
    padding: 0;
    height: 100%;
    overflow-y: auto;

    scrollbar-color: #009878 #d7d7cd;

    &::-webkit-scrollbar {
      height: 13px;
      width: 13px;
      background: #d7d7cd;
    }

    &::-webkit-scrollbar-thumb {
      height: 13px;
      background: #009878;
      border: 3px solid #009878;
    }

    &::-webkit-scrollbar-corner {
      background: #d7d7cd;
    }

    > .row {
      position: sticky;
      top: 0;
      background: #fff;
    }

    .cgu-body {
      height: 100%;
      padding: 0 1rem 4rem 1rem;
    }
  }

  .btn-actions {
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
      border-radius: 0.25rem;
      width: 10rem;

      &.btn-link {
        svg {
          border: 1px solid #333333;
          border-radius: 50%;
        }
      }

      &.btn-primary {
        background-color: #009878 !important;
        border: 2px solid #009878 !important;
        &:hover {
          background-color: #3ba39b !important;
        }
      }
    }
  }
}

.modal-dialog {
  .btn {
    border-radius: 0.25rem;
  }
}
.modal-body {
  height: 25em;
  overflow-y: auto;
}

.bnt-space {
  margin: 1rem;
}
.email-tooltip {
  display: flex;
  flex-direction: row;
  padding: 1rem 0 1rem 0;
  font-size: 15px !important;
  .svg-eye {
    width: 50px;
    height: 50px;
    margin: -1rem 0.5rem 0 0;
  }
}

.grecaptcha-badge {
  bottom: 90px !important;
}

.email-success{
  color:#009878;
}