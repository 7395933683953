.CGUContent {
  padding: 0 3rem;
  font-size: 16px;
  .label-title {
    justify-content: center;
    margin-bottom: 1.5rem;
    color: #009978;
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: bold;
  }

  p {
    text-align: justify;
  }

  a {
    color: #009878;
  }

  .mention-content {
    margin: 0 3rem;
  }

  ol > li > span {
    font-weight: bold;
  }

  ol li p {
    margin-top: 1rem;
  }

  .font-weight-600 {
    font-weight: 600;
  }

  ul {
    list-style: inside;
    padding: 0 0 0 60px;
  }

  ol {
    padding: 0;
    counter-reset: item;
    > li {
      display: block;
      margin-top: 1rem;
    }

    > li:before {
      content: counters(item, ".") ". ";
      counter-increment: item;
      font-weight: bold;
    }

    ol {
      padding: 0 0 0 40px;
      > li {
        font-weight: bold;
      }
      > li:before {
        content: counters(item, ".") " ";
      }
    }
  }

  ol {
    ul {
      padding: 0 0 0 40px;
    }
  }
  li {
    margin-bottom: 1rem;
  }
}
