#my-dropdown-toggle {
    background-color: white;
    border-color: white;
}

#my-dropdown-toggle:after {
    display: none;
}

.dropdown-item span{
    color: #009878 !important;
}

.dropdown-toggle:focus {
    box-shadow: none !important;
}
.dropdown-item:active {
    background-color: #59a491 !important;
    color: white !important;
}

.header {
    .actions {
        .btn {
            padding: 0.2rem 0.75rem;
        }
    }
}