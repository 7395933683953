@import "~bootstrap/scss/bootstrap";
@import "~react-datepicker/dist/react-datepicker.css";
@import "./zindex.scss";

$ps-primary1: #31a550;
$ps-primary2: #408c7a;
$ps-primary3: #cab93e;
$ps-secondary1: #000000;
$ps-secondary2: #d7d7cd;
$ps-secondary3: #ffffff;

$theme-colors: (
  "primary": $ps-primary1,
  "secondary": $ps-secondary2,
  "info": $ps-primary3,
  "danger": rgb(145, 59, 59),
  "lightgreen": #3f5739,
);

// BOOTSTRAP OVERWRITE
.container-fluid {
  padding: 0 !important;
  height: 100%;
}

.form-control {
  background-color: #fff !important;
}

.btn {
  border-radius: 25px;
  padding: 0.5em 2em;
}

.row {
  margin: 0;
  flex-wrap: nowrap;
}

[class*="col-"],
.col {
  padding: 0.2em;
}

// APP

html,
body {
  height: 100%;
  margin: 0px;
  font-family: Mada, sans-serif;
  font-size: 15px;
  // background-color: #fff!important;
}

body > iframe {
  z-index: -1 !important;
}

.content {
  padding: 0 !important;
}

#root,
.App {
  height: 100%;
  width: 100%;
}

.theme-territoire-portail {
  .pro-sidebar {
    background: linear-gradient(hsl(167, 100%, 30%), #26a896) !important;
    z-index: 1050 !important;

    .pro-sidebar-content {
      background: url(./assets/svg/bg-menu.svg) no-repeat;
      background-position: bottom;
      background-repeat: no-repeat;

      .pro-menu {
        background: #009878 !important;
      }
    }
  }
}

.toastQueue {
  z-index: 20000000000 !important;
}

.toastQueue div.toastElem .toastBody {
  max-height: 80vh !important;
  overflow-y: auto;
}

.theme-territoire-portail
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: #009878 !important;
  background-color: #009878 !important;
}

.app {
  text-align: center;
  position: relative;
}

.pro-sidebar .pro-sidebar-inner .pro-sidebar-content,
.layout .row .content,
.modal-body,
textarea {
  scrollbar-color: #009878 #d7d7cd;

  &::-webkit-scrollbar {
    height: 13px;
    width: 13px;
    background: #d7d7cd;
  }

  &::-webkit-scrollbar-thumb {
    background: #009878;
    border: 3px solid #009878;
  }

  &::-webkit-scrollbar-corner {
    background: #d7d7cd;
  }
}

.custom-link {
  color: #009878;
}

.custom-link:hover {
  color: #009878;
}

.signup-scroll {
  overflow-y: auto;
  scrollbar-color: #009878 #d7d7cd;

  &::-webkit-scrollbar {
    height: 13px;
    width: 13px;
    background: #d7d7cd;
  }

  &::-webkit-scrollbar-thumb {
    background: #009878;
    border: 3px solid #009878;
  }

  &::-webkit-scrollbar-corner {
    background: #d7d7cd;
  }
}

// JODIT PLUGIN

.jodit_fullsize-box_true .footer {
  display: none !important;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle,
.jodit-ui-button_variant_primary,
.jodit-ui-button_variant_primary:hover,
.jodit-ui-button_variant_primary:active,
.jodit-ui-button_variant_primary.active {
  color: #fff !important;
  background-color: #009878 !important;
  border-color: #009878 !important;
}

.jodit-ui-input_focused_true .jodit-ui-input__wrapper {
  border-color: #009878 !important;
}

.jodit-ui-checkbox__wrapper {
  input[type="checkbox"]:after {
    background-color: #FFFFFF;
    content: '';
    display: block;
    height: 15px;
    position: relative;
    width: 15px;
    border: 1px solid #9e9e9e;
  }

  input[type="checkbox"]:checked:after {
    background-image: url("./assets/svg/check-blanche-bk-vert.svg");
    border: none;
    background-repeat: no-repeat;
    background-size: 100%;
    -moz-appearance: initial;
  }
}


.tableau-optimized {

  .table-column-title {
    font-size: 85%;
  }
  .list-group-item {
    white-space: nowrap;
    padding: 0 0 5px;
  }
  td {
    vertical-align: top!important;
    padding: 0.7rem;
  }
  &.size-control-high {
    .CDataTable .table-responsive {
      overflow-y: auto;
      max-height: 700px;
    }
    thead {
      position: sticky;
      top: 0;
      z-index: 90;
    }
  }

  &.size-control-medium {
    .CDataTable .table-responsive {
      overflow-y: auto;
      max-height: 550px;
    }
    thead {
      position: sticky;
      top: 0;
      z-index: 90;
    }
  }

  &.size-control-low {
    .CDataTable .table-responsive {
      overflow-y: auto;
      max-height: 400px;
    }
    thead {
      position: sticky;
      top: 0;
      z-index: 90;
    }
  }

}

.custom-tooltip {
  z-index: $zindexTooltip !important;
}