.CLoginForm {
  height: 100%;
  font-family: "Mada", sans-serif;

  .btn-primary.focus,
  .btn-primary:focus,
  .btn-outline-success.focus,
  .btn-outline-success:focus {
    box-shadow: 0 0 0 0.2rem rgba(64, 139, 122, 0.4);
  }
  .login .login-form form .title {
    margin: 0.4em 0 !important;
  }

  .login-page {
    background: url(../../../assets/svg/bg-bottom-left.svg),
      url(../../../assets/svg/bg-top-right.svg),
      linear-gradient(190deg, #009878 30.73%, #26a896 100%);
    //background-image: url(../../assets/svg/bg.svg);
    background-position: bottom 8% left, top right;
    background-repeat: no-repeat;
    height: 100%;

    .login .login-form form {
      border-radius: 5px;
      box-shadow: 0 0 3em #666060;
      padding: 1.5rem;
      min-height: 525px;
      width: 700px;

      .logo {
        margin: 0 !important;
        img {
          height: 7rem;
          margin: 1rem 0;
        }
      }
    }

    .form-control.is-invalid,
    .was-validated .form-control:invalid {
      border-color: #ff6565;
      border-width: 2px;
    }

    .invalid-feedback,
    .error-form {
      color: #ff6565;
      text-align: left !important;
      font-size: 12px;
    }

    > form {
      scrollbar-color: #009878 #d7d7cd;

      &::-webkit-scrollbar {
        height: 13px;
        width: 13px;
        background: #d7d7cd;
      }

      &::-webkit-scrollbar-thumb {
        background: #009878;
        border: 3px solid #009878;
      }

      &::-webkit-scrollbar-corner {
        background: #d7d7cd;
      }
    }
  }

  .login {
    height: 90% !important;
    overflow-y: auto;

    scrollbar-color: #009878 #d7d7cd;

    &::-webkit-scrollbar {
      height: 13px;
      width: 13px;
      background: #d7d7cd;
    }

    &::-webkit-scrollbar-thumb {
      background: #009878;
      border: 3px solid #009878;
    }

    &::-webkit-scrollbar-corner {
      background: #d7d7cd;
    }
  }

  .icon-tooltip {
    color: #009878 !important;
  }

  .login .login-form form .actions,
  .actions {
    text-align: center;
    .btn {
      padding: 0.5rem 1rem 0.5rem 1rem;
      background-color: #009878 !important;
      border: 2px solid #009878 !important;
      color: #ffffff;
      font-weight: 600;
      width: 30%;
      &:hover {
        background-color: #3ba39b !important;
      }
    }

    .btn-outline-success {
      background-color: transparent !important;
      color: #009878;
      border: 2px solid #009878 !important;
    }

    .btn-outline-success:hover {
      background-color: #009878 !important;
      color: #ffffff;
      border: 2px solid #009878 !important;
    }
  }

  .forgot-mdp {
    cursor: pointer;
    color: #009878;
    font-size: 14px;
    text-decoration: underline;
    font-weight: 500;
    text-align: center;
    display: block;
    margin-top: 1.5rem;
    &:hover {
      color: #3ba39b !important;
    }
  }
}
