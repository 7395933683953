.CDataTable {
  .table-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #000000d9;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    z-index: 0;
    display: block;
  }

  .filter-label {
    width: 100%;
    padding: 8px;
    margin: 0;
  }

  .react-bootstrap-table {
    margin-bottom: 1rem;
  }

  .react-bootstrap-table table {
    table-layout: auto !important;
    margin-bottom: 0 !important;
  }

  .react-bootstrap-table.table-responsive {
    scrollbar-color: #009878 #d7d7cd;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      height: 10px;
      width: 10px;
      background: #d7d7cd;
    }

    &::-webkit-scrollbar-thumb {
      background: #009878;
      border: 2px solid #009878;
    }

    &::-webkit-scrollbar-corner {
      background: #d7d7cd;
    }
  }

  .react-bootstrap-table > table > thead > tr > th:not(.selection-cell-header) {
    color: #333333;
    text-align: left;
    background: #D7D7CD;
    transition: background 0.3s ease;
    font-weight: bold;
    min-width: 200px !important;
  }

  .react-bootstrap-table > table > thead > tr > th.selection-cell-header {
    position: relative;
    background: #D7D7CD;
    transition: background 0.3s ease;
    text-align: center;
    width: 56px;
    padding: 0 0.75rem;
    input {
      line-height: 0;
      width: 20px;
    }
  }

  .react-bootstrap-table > table > tbody > tr > td.selection-cell {
    text-align: center;

    input {
      line-height: 0;
      width: 20px;
    }
  }

  .form-check-label {
    margin-left: 6px;
  }

  .table-responsive {
    overflow-y: hidden;
  }

  .table {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #000000d9;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    font-size: 14px;
    background: #fff;
    border-radius: 2px;
    border-spacing: 0;
    border-width: 1px;
    border-collapse: inherit;
  }

  table > thead {
    z-index: 1;
  }

  .table th {
    padding: 0;
  }

  .table-bordered {
    border-left: 1px solid rgba(51, 51, 51, 0.3) !important;
    border-bottom: 1px solid rgba(51, 51, 51, 0.3) !important;
  }

  .table-hover tbody tr:hover {
    cursor: pointer;
    background-color: #EFEFEF !important;
  }

  .table-striped tbody tr:nth-of-type(2n) {
    //background-color: rgba(243, 243, 243, 0.7);
    background-color: #F8F7F7;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #FFFFFF;
  }

  .table td {
    border-right: 1px solid rgba(51, 51, 51, 0.3);
    vertical-align: middle;
    border-top: 0px;
    font-family: "Mada";
  }

  .table td:first-child {
    border-left: 1px solid rgba(51, 51, 51, 0.3);
  }

  .table tr:last-child td {
    border-bottom: 1px solid rgba(51, 51, 51, 0.3);
  }

  .table th {
    border-top: 1px solid rgba(51, 51, 51, 0.3);
    border-right: 1px solid rgba(51, 51, 51, 0.3);
    border-bottom: 0px;
    vertical-align: middle;
  }

  .table th:first-child {
    border-left: 1px solid rgba(51, 51, 51, 0.3);
  }

  .table-bordered th {
    border-right: 1px solid rgba(51, 51, 51, 0.3) !important;
  }

  .table-bordered tr td {
    border: unset;
    border-right: 1px solid rgba(51, 51, 51, 0.3) !important;
  }

  .table-bordered tbody tr:not(:last-child) td {
    border-bottom: 1px solid rgba(51, 51, 51, 0.3) !important;
  }

  .table-column-sorter-inner {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }

  .table-column-sorter-down {
    margin-top: -0.3em;
  }

  .table-filter-column {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 44.5px;
  }

  .table-column-title-container {
    flex: 1;
  }

  .table-column-title {
    flex: 1 1;
    padding: 0 20px;
    line-height: 16px;
    font-weight: 700;
    font-family: "Mada";
  }

  .table-column-sorters {
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: space-between;
  }

  .table-column-sorter {
    color: #3e3e3d;
    transition: color 0.3s;
    padding: 0.75rem;
    background-color: #E3E3DC;
    border-left: 1px solid rgba(51, 51, 51, 0.3);
  }

  .table-filter-trigger {
    align-items: center;
    //color: #3e3e3d !important;
    font-size: 12px;
    padding: 0.75rem;
    cursor: pointer;
    transition: all 0.3s;
    background-color: #E3E3DC;
    border-left: 1px solid rgba(51, 51, 51, 0.3);
  }

  .table-filter-trigger:hover {
    color: #00000073;
    //background: rgba(0, 0, 0, 0.04);
  }

  .table-filter-trigger .table-dropdown a {
    color: #3e3e3d !important;
  }

  .sorticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* PAGINATION */

  .react-bootstrap-table-pagination {
    display: flex;
    width: 100%;
    margin: 0 0 10px 0 !important;
    justify-content: center;
  }
  .react-bootstrap-table-pagination {
    .btn-block-pagination {
      display: flex;
      align-items: center;
    }
    .btn-pagination {
      cursor: pointer;
      img {
        height: 50%;
        width: 1rem;
        margin-left: 1rem;
      }
    }

    .btn-pagination.disable {
      cursor: no-drop;
    }
  }

  .react-bootstrap-table-pagination-total {
    margin: auto 0;
  }

  .react-bootstrap-table-pagination div:first-child {
    padding-left: 0;
  }

  .react-bootstrap-table-pagination div {
    display: contents;
  }

  .pagination.react-bootstrap-table-page-btns-ul {
    margin-bottom: 0 !important;
    float: right;
  }

  .pagination.react-bootstrap-table-page-btns-ul .page-item.active .page-link {
    z-index: 3;
    color: #505050;
    background-color: #d8d7cc;
    border-color: #d8d7cc;
  }

  .pagination.react-bootstrap-table-page-btns-ul .page-link {
    border: 0;
    color: #505050;
  }

  .pagination.react-bootstrap-table-page-btns-ul
    .page-item:first-child
    .page-link {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .pagination.react-bootstrap-table-page-btns-ul .page-item .page-link {
    border-left: 1px solid #505050;
  }

  .pagination.react-bootstrap-table-page-btns-ul
    .page-item:first-child
    > .page-link,
  .pagination.react-bootstrap-table-page-btns-ul
    .page-item:last-child
    > .page-link,
  .pagination.react-bootstrap-table-page-btns-ul
    .page-item:nth-child(2)
    > .page-link,
  .pagination.react-bootstrap-table-page-btns-ul
    .page-item:nth-last-child(2)
    > .page-link,
  .pagination.react-bootstrap-table-page-btns-ul
    .page-item:nth-child(3)
    > .page-link {
    border-left: 0;
  }

  .pagination.react-bootstrap-table-page-btns-ul .page-link:focus {
    box-shadow: none;
  }

  .font-weight-600 {
    font-weight: 600;
  }

  .dropdown-divider {
    margin: 0.5rem 0 !important;
  }

  tr > .react-bs-table-no-data {
    text-align: center;
    cursor: default;
  }

  tr > .react-bs-table-no-data:hover {
    background-color: #ffffff;
  }

  .table-empty {
    font-size: 14px;
    line-height: 1.5715;
    text-align: center;
    margin: 8px;
  }
  .table-empty-image svg {
    height: 50px;
    color: #d7d7cd;
  }

  .table-dropdown-menu {
    min-width: 15rem !important;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
      0 9px 28px 8px #0000000d;
    border: none !important;
    padding: 0 !important;
    z-index: 10000;

    .btn-link,
    .btn-link:hover {
      color: #59a491 !important;
    }

    .btn-primary {
      color: #fff;
      background-color: #009978;
      border-color: #009978;
    }

    .btn-primary:hover {
      color: #fff;
      background-color: #59a491;
      border-color: #59a491;
    }

    .btn-primary:not(:disabled):not(.disabled).active,
    .btn-primary:not(:disabled):not(.disabled):active,
    .show > .btn-primary.dropdown-toggle {
      color: #fff;
      background-color: #408c7a;
      border-color: #408c7a;
    }

    .btn-primary:not(:disabled):not(.disabled).active:focus,
    .btn-primary:not(:disabled):not(.disabled):active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: none;
    }
  }

  input[type="checkbox"] {
    -moz-appearance: initial;
  }

  input[type="checkbox"]:after {
    -moz-appearance: initial;
    content: " ";
    height: 16px;
    width: 16px;
    background-color: #ffffff;
    display: inline-block;
    visibility: visible;
    border: 1px solid #afafaf;
    position: inherit;
    padding-left: 4px;
  }

  input[type="radio"]:after {
    -moz-appearance: initial;
    content: " ";
    height: 16px;
    width: 16px;
    background-color: #ffffff;
    display: inline-block;
    visibility: visible;
    border: 1px solid #afafaf;
    position: absolute;
    padding-left: 4px;
    margin-top: -2px;
    border-radius: 50px;
  }

  input[type="checkbox"]:checked:after,
  input[type="radio"]:checked:after {
    -moz-appearance: initial;
    background-color: #59a491;
    border: 1px solid #59a491;
    color: #ffffff;
  }

  .table-dropdown-menu-content {
    position: relative;
    margin: 0;
    padding: 4px 0;
    text-align: left;
    list-style-type: none;
    max-height: 225px;
    overflow-y: auto;
  }

  .table-dropdown-menu-item {
    clear: both;
    margin: 0;
    padding: 5px 12px;
    color: #000000d9;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.3s;
  }

  .table-dropdown-menu-content {
    -moz-appearance: initial;
    scrollbar-color: #408c7a #d7d7cd;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      height: 10px;
      width: 10px;
      background: #d7d7cd;
    }

    &::-webkit-scrollbar-thumb {
      background: #408c7a;
      border: 2px solid #408c7a;
    }

    &::-webkit-scrollbar-corner {
      background: #d7d7cd;
    }
  }

  .table-filter-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #000000d9;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    min-width: 120px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
      0 9px 28px 8px #0000000d;
  }

  .table-filter-dropdown .btn {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    border: 1px solid transparent;
    box-shadow: 0 2px #00000004;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
  }

  .table-filter-dropdown .btn-sm {
    height: 24px;
    padding: 0 7px;
    font-size: 14px;
    border-radius: 2px;
    width: 100%;
  }

  .table-input {
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: #000000d9;
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 2px solid #59a491;
    border-radius: 4px;
    transition: all 0.3s;
    margin-bottom: 8px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .table-input:focus {
    border-color: #59a491;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(89, 164, 145, 0.2);
  }

  .table-space {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
  }

  .table-space-item,
  .table-space {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .onoffswitch {
    text-align: center;
  }

  input[type="checkbox"]:after {
    -moz-appearance: initial;
    content: " ";
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    display: inline-block;
    visibility: visible;
    border: 1px solid #9E9E9E;
    position: inherit;
    padding-left: 4px;
    margin-top: -2px;
    border-radius: 2px;
  }

  input[type="checkbox"]:checked:after {
    background-image: url("../../../assets/svg/check-blanche-bk-vert.svg");
    border: none;
    background-repeat: no-repeat;
    background-size: 100%;
    -moz-appearance: initial;
  }

  .bg-filter {
    background: #009978 !important;
  }
  
  thead {
    .table-dropdown {
      .table-dropdown-menu-item {
        display: flex !important;

        .form-check {
          display: inline !important;

          label {
            font-weight: unset !important;
          }
        }
      }
    }

    .CInfoButton {
      margin: 0 0.3rem 0 0;
      background-size: 15px;
      width: 50px;
      height: 15px;
    }
  }

}

.modal-dialog .CDataTable {
  .loading-overlay {
    overflow: hidden;

    ._loading_overlay_overlay {
      width: 10000px;

      ._loading_overlay_content {
        margin: unset;
        position: fixed;
        top: 200px;
        left: 47%;
      }
    }
  }
}
