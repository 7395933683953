.layout {
  display: flex;
  flex-flow: column;
  height: 100%;

  .body {
    flex: 1 1 auto;
    width: 100%;
    margin: 0;
    margin-right: 0;
    margin-left: 0;
  }

  main {
    padding: 0 2rem 8rem 2rem;
  }

  .layout-row {
    width: 100%;
  }

  .layout-row,
  .left-column {
    border: 0;
    height: 90vh;
    max-height: 100vh;
  }

  .pro-sidebar-inner {
    overflow: hidden;
  }

  .pro-sidebar .pro-menu a svg path,
  .pro-sidebar .pro-menu .pro-icon svg path {
    fill: #ffffff;
  }

  svg circle{
    stroke: #ffffff;
  }

  .active{
    svg circle{
      stroke: #5f5f5f!important;
    }
  }

  .pro-sidebar
    .pro-menu
    .pro-menu-item.pro-sub-menu
    .pro-inner-list-item
    .popper-inner {
    padding: 15px 0 15px 0 !important;
  }

  .pro-sidebar .pro-menu a.active svg path circle{
    fill: #555555 !important;
  }

  .pro-sidebar .pro-menu .pro-icon svg,
  .pro-sidebar .pro-menu a svg {
    height: 24px;
    width: 24px;
  }

  .logo-title {
    color: #009878;
    font-size: 1.5em;
    margin-left: 1.3rem;
  }

  .onf-logo-header {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
  }
  .app-header {
    height: 4rem !important;
    background-color: #009878 !important;
  }
  .header {
    background: #ffffff !important;
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem 0 0;
    color: white;
    box-shadow: 0px 0 8px 0px #a39e9e;
    //border-bottom: 2px solid #ececec;
    z-index: 1050;

    .title {
      //padding: 1rem;
      h1 {
        font-size: 1.4rem;
        margin: 0;
      }

      @media screen and (max-height: 930px) {
        .onf-logo-header {
          display: flex !important;
          flex-direction: row !important;
          align-items: center !important;

          a {
            width: auto;
            img {
              //width: 100%;
              image-rendering: -moz-crisp-edges; /* Firefox */
              image-rendering: -o-crisp-edges; /* Opera */
              image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
              image-rendering: crisp-edges;
              -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
              height: 8vh;
            }
          }

          h4 {
            padding-left: 0;
          }
        }
      }

      @media screen and (min-height: 931px) {
        .onf-logo-header {
          display: flex !important;
          flex-direction: row !important;
          align-items: center !important;

          a {
            width: auto;
            img {
              //width: 100%;
              image-rendering: -moz-crisp-edges; /* Firefox */
              image-rendering: -o-crisp-edges; /* Opera */
              image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
              image-rendering: crisp-edges;
              -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
              height: 5vh;
            }
          }

          h4 {
            padding-left: 0;
          }
        }
      }
    }

    .actions {
      button {
        margin-right: 0.2em;
      }
    }
  }

  .footer {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    background: #ebe9e6;
    padding: 17.5px 5% !important;
    margin: 0 0 0 270px !important;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    height: 57px;
    transition: width, left, right, 0.3s;
    align-items: center;
    a {
      flex: auto;
      cursor: pointer;
      color: #555555 !important;
      line-height: 1.2rem;
      padding: 0 0.5rem;
    }
  }

  .footer.collapsed {
    margin: 0 0 0 70px !important;
  }

  .footer-page-parent {
    overflow-y: hidden;
  }

  .footer-page {
    height: 100%;
    padding: 0 0 14.8rem 0;
  }

  .row .content {
    background-color: #ffffff;
    height: 90vh;
    > div {
      margin: 1.5rem;
    }
  }

  .parametre-page-parent {
    overflow-y: hidden;
  }

  .parametre-page {
    padding: 0 0 6rem 0!important;
    height: 100%;
  }

  .row .content {
    background-color: #ffffff;
    height: 90vh;
  }
  /*** TABS ***/

  .nav-tabs {
    border-bottom: 0;
    background: #009878;
    padding-top: 1rem;
    padding-left: 5rem;

    .nav-link {
      color: #ffffff;
      background-color: transparent;
    }

    .nav-link {
      border: 0 !important  ;
      border-radius: 0;
      line-height: 15px;
      padding: 15px 50px 15px 0;
      text-align: center;
    }

    .nav-link:focus,
    .nav-link:hover {
      border: none;
    }

    .nav-link:not(:first-child):after {
      content: "";
      height: 100%;
      width: 1px;
      float: left;
      margin-right: 50px;
    }

    .nav-link:not(.active):after {
        border-right: 1px solid;
    }

    .nav-link:first-child {
      padding-left: 50px;
    }

    .nav-link.active {
      color: #3a3a3a !important;
      font-weight: bold;
      border-bottom: none !important;
    }

    .nav-link.active + .nav-link:after {
      background-color: transparent;
      border-right-width: 0px;
    }
  }

  .tab-content {
    //padding: 1.5rem 0;

    .nav-tabs {
      background: #ffffff !important;
      padding: 0;
      align-items: center;

      .nav-link {
        color: #3a3a3a;
        font-weight: bold;
        background-color: transparent;
        padding: 15px 50px;
      }

      .nav-link.active {
        border-bottom: 4px solid #009878 !important;
      }
    }
  }

  /*** BTN ***/
  .btn-outline-success,
  .btn-outline-secondary {
    font-weight: 600;
    padding: 0.5rem 30px;
    border-radius: 2em;

    svg {
      margin-right: 0.5rem;
    }
  }

  .btns-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
  }

  .btns-actions-table {
    display: flex;
    justify-content: center;

    .action-remove svg path {
      fill: #bd2130;
    }
  }

  .btns-export-container {
    display: flex;
    text-align: right;
    //padding-right: 1rem;
  }

  .btn-outline-success {
    color: #2f2f2f;
    border: 2px solid #009878;
    font-weight: 600;
    border-radius: 2em;
    margin-right: 0.5rem;
  }

  .btn-primary.focus,
  .btn-primary:focus,
  .btn-outline-success.focus,
  .btn-outline-success:focus {
    box-shadow: 0 0 0 0.2rem rgba(64, 139, 122, 0.4);
  }

  .btn-outline-success:hover,
  .btn-outline-success:not(:disabled):not(.disabled).active,
  .btn-outline-success:not(:disabled):not(.disabled):active,
  .show > .btn-outline-success.dropdown-toggle {
    background-color: #009878;
    border-color: #009878;
  }

  .btn-link {
    color: #333333;
    font-weight: 500;
    padding-left: 0;
    padding-right: 0;

    display: flex;
    align-items: center;

    svg,
    img {
      margin-right: 0.2em;
      height: 22px;
      width: 22px;
    }
  }

  .btn-link:hover {
    color: #626467 !important;
  }

  .btn-link:focus {
    box-shadow: none;
  }

  .btn-outline-success.disabled,
  .btn-outline-success:disabled {
    color: #418c77;
    background-color: transparent;
    cursor: no-drop;
  }

  /*** INPUT ***/

  .form-control {
    border-radius: 4px !important;
    border: 1px solid #b4b4b4;
  }

  .form-control:focus {
    box-shadow: 0 0 0 0.1rem rgba(64, 140, 122, 0.5) !important;
  }

  .form-control.is-invalid:focus,
  .was-validated .form-control:invalid:focus {
    border-color: #dc3545 !important;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25%) !important;
  }

  .table-data {
    margin: 4em 0 2rem 0;

    .table-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 1em;
      width: 100%;

      .header-buttons {
        display: flex;
        justify-content: flex-end;
        margin: 0;
      }
    }

    .table-loading {
      overflow-x: hidden !important;
    }
  }

  .label-title {
    color: #009878;
    display: flex;
    align-items: center;
  }
  .label-title-with-btns {
    color: #009878;
    display: flex;
    align-items: center;
    width: 30%;
  }

  @media only screen and (min-height: 930px) {
    .header {
      height: 7vh;
    }

    .layout-row,
    .left-column {
      height: 93vh;
    }

    main {
      padding: 2rem 2rem 10rem 2rem;
    }

    .footer-page {
      height: 100%;
      padding: 0 0 17rem 0;
    }
  }

  .invalid-feedback {
    display: block;
  }

  .container-rules {
    width: 100%;
    .container-left,
    .container-right {
      display: flex;
    }

    .container-left {
      justify-content: flex-end;
    }

    .container-right {
      justify-content: flex-start;
    }
  }
}

.pastile-rouge {
  height: 20px;
  margin-left: 10px;
}

.confirmation-text {
  text-align: center;
  margin-top: 2rem;
}

.box-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn.btn-success,
.modal-dialog .btn.btn-success {
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #009878;
  border: 2px solid #009878;
  color: #ffffff;
  font-weight: 600;
  min-width: 10rem;
  &:hover {
    background-color: #3ba39b;
  }
}

.modal-dialog {
  .btn-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    button:first-child {
      margin-right: 2rem;
      svg {
        border: 1px solid #333;
        border-radius: 50%;
      }
    }
  }
}

.custom-alert {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 1rem 0;
  align-items: center;

  div:last-child {
    flex: 1;
    text-align: left;
    margin-left: 1.25rem;
  }

  &.info {
    background-color: #d8e6f3;
    color: #21a6c3;
    border: 2px solid #21a6c3;
  }

  &.success {
    background-color: #d8f3de;
    color: #009878;
    border: 2px solid #009878;
  }

  &.error {
    background-color: #f3d8da;
    color: #a53131;
    border: 2px solid #a53131;
  }

  &.info-md {
    background-color: #f3e5d8;
    color: #d9601c;
    border: 2px solid #d9601c;
  }

  &.warning {
    background-color: #fff3f3;
    color: #a53131;
    border: 2px solid #a53131;
  }
}

.switch.btn {
  min-width: 6rem !important;
  border-radius: 50px;
  padding: 0.5rem !important;
  border: 0 !important;
  background: #9e9e9e;
}

.switch-on.btn.btn-success {
  color: #fff;
  background-color: #009878;
  border: 0 !important;
  border-color: #009878;
  min-width: 6rem !important;
  padding: 0.4rem !important;
}

.switch-off.btn.btn-light {
  color: #333333;
  background-color: #9e9e9e;
  border-color: #9e9e9e;
}

.switch.btn.on .switch-off.btn.btn-light {
  background-color: #009878;
  border-color: #009878;
}
.switch.btn.off {
  padding-left: 2rem;
}

.switch.btn.on {
  padding-right: 2rem;
}

.switch.btn.off .switch-handle {
  margin-right: -2rem;
}

.switch.btn.on .switch-handle {
  margin-left: -2rem;
}

.switch-off.btn {
  padding-left: 2.2rem;
}

.switch-on.btn {
  padding-right: 2.2rem;
}

.switch-handle {
  border-radius: 100% !important;
}

.switch-group {
  padding: 4px 0 !important;
}

.switch-group span {
  font-weight: 600;
}

.btn-sm {
  .switch-group {
    padding: 3% 0;
  }
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #3ba39b !important;
  border-color: #3ba39b !important;
}
