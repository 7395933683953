.MessageParametrableModal {
  .modal-dialog {
    &.modal-lg {
      max-width: 70% ! important;
    }
    .modal-content {
      max-height: none !important;
    }

    .modal-title-param {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 22px;
      margin: 1rem 0;
      .label-info {
        margin-top: 1rem;
        color: #21a6c3;
      }
      .label-alert {
        margin-top: 1rem;
        color: #d9601c;
      }
    }

    .confirmation-btn {
      padding: 0.7rem 5rem 0.7rem 5rem !important;
    }
    .modal-body-desc {
      padding: 1rem 2rem;
      font-size: 16px;
      overflow-y: auto;
      scrollbar-color: #009878 #d7d7cd;

      &::-webkit-scrollbar {
        height: 13px;
        width: 13px;
        background: #d7d7cd;
      }

      &::-webkit-scrollbar-thumb {
        background: #009878;
        border: 3px solid #009878;
      }

      &::-webkit-scrollbar-corner {
        background: #d7d7cd;
      }
    }

    input[type="checkbox"] {
      background-color: #ffffff;
      border: 0;
      cursor: pointer;
      display: inline-block;
      height: 25px;
      position: relative;
      width: 25px;
      -webkit-appearance: none;
    }

    input[type="checkbox"] + label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.3rem;
      margin-left: 0.6rem;
      font-weight: 500;
    }

    .form-check {
      position: relative;
      margin: 1rem 2rem;
      display: flex;
      align-items: center;
    }

    input[type="checkbox"]:checked:after {
      background-image: url("../../../assets/svg/check-verte-bk-transp.svg");
      border: none;
      //color: #ffffff;
      background-repeat: no-repeat;
      background-size: 100%;
      -moz-appearance: initial;
      height: 25px;
      width: 25px;
    }

    input[type="checkbox"]:after {
      -moz-appearance: initial;
      content: " ";
      height: 25px;
      width: 25px;
      background-color: #ffffff;
      display: inline-block;
      visibility: visible;
      border: 1px solid #9e9e9e;
      position: inherit;
      border-radius: 2px;
    }

    input[type="checkbox"]:checked:after {
      background-image: url("../../../assets/svg/check-blanche-bk-vert.svg");
      border: none;
      background-repeat: no-repeat;
      background-size: 100%;
      -moz-appearance: initial;
    }

    .modal-header {
      background: #009878;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      padding: 0.5rem;
      color: #fff;
    }

    /*** BTN ***/
    .inline-btn-bloc-modal {
      display: flex;
      justify-content: center;
      padding: 2rem;
    }

    .btn {
      font-weight: 600;
      padding: 0.5rem 2rem;
      border-radius: 2em;
    }

    .btn-outline-success {
      color: #2f2f2f;
      border: 2px solid #408c7a;
    }

    .btn-outline-success.focus,
    .btn-outline-success:focus {
      box-shadow: 0 0 0 0.2rem rgba(64, 139, 122, 0.4);
    }

    .btn-outline-success:hover {
      background-color: #009878;
      border-color: #009878;
      color: #FFFFFF;
    }

    .btn-link {
      color: #333333;
      font-weight: 500;
      padding-left: 0;
      padding-right: 0;

      svg {
        margin-right: 0.2em;
        height: 22px;
        width: 22px;
      }
    }

    .btn-link:hover {
      color: #626467 !important;
    }

    .btn-link:focus {
      box-shadow: none;
    }

    .btn-outline-success.disabled,
    .btn-outline-success:disabled {
      color: #009878;
      background-color: transparent;
      cursor: no-drop;
    }

    input[type="radio"]:after {
      -moz-appearance: initial;
      content: " ";
      height: 16px;
      width: 16px;
      background-color: #ffffff;
      display: inline-block;
      visibility: visible;
      border: 1px solid #afafaf;
      position: absolute;
      padding-left: 4px;
      margin-top: -2px;
      border-radius: 50px;
    }

    input[type="radio"]:checked:after {
      -moz-appearance: initial;
      background-color: #009878;
      border: 1px solid #009878;
      color: #ffffff;
    }
  }
}
