.custom-tooltip .tooltip-inner {
  padding: 0.8rem;
  border-radius: .5rem !important;
  border: 0.5px solid #59a491 !important;
  background-color: #59a491 !important;
  color: white !important;
  font-size: 14px !important;
  max-width: 300px!important;
  margin: 0.3em;
}

.custom-tooltip.bs-tooltip-left .arrow::before {
  border-left-color: #65aa95;
  left: -1px;
}

.custom-tooltip.bs-tooltip-right .arrow::before {
  border-right-color: #65aa95;
  right: -1px;
}

.custom-tooltip.bs-tooltip-top .arrow::before {
  border-top-color: #65aa95;
  top: -1px;
}

.custom-tooltip.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #65aa95;
  bottom: -1px;
}