.avatar-wrapper {
  position: relative;
  height: 200px;
  width: 200px;
  margin: 50px auto 20px auto;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 1px 1px 15px -5px black;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  &:hover .profile-pic {
    opacity: 0.5;
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  .spinner:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #009878;
    animation: spinner 0.6s linear infinite;
  }

  .profile-pic {
    height: 100%;
    width: 100%;
    transition: all 0.3s ease;
    &:after {
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      font-size: 190px;
      background: #fff;
      color: #34495e;
      text-align: center;
    }
  }
  .upload-button {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    .cloud-upload {
      display: block;
      margin: auto;

      font-size: 100px;
      text-align: center;
      opacity: 0;
      transition: all 0.3s ease;
      color: #34495e;
    }
    &:hover .cloud-upload {
      opacity: 0.9;
    }
  }
}
