.modal-dialog {
  .modal-header {
    background: #009878;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    color: #fff;
    font-size: 20px;
  }

  /*** BTN ***/
  .inline-btn-bloc-modal {
    display: flex;
    justify-content: center;
    padding: 2rem;
  }

  .btn {
    font-weight: 600;
    padding: 0.5rem 2rem;
    border-radius: 2em;
  }

  .btn-outline-success {
    color: #2f2f2f;
    border: 2px solid #408c7a;
  }

  .btn-outline-success.focus,
  .btn-outline-success:focus {
    box-shadow: 0 0 0 0.2rem rgba(64, 139, 122, 0.4);
  }

  .btn-outline-success:hover {
    background-color: #009878;
    border-color: #009878;
  }

  .btn-link {
    color: #333333;
    font-weight: 500;
    padding-left: 0;
    padding-right: 0;

    svg {
      margin-right: 0.2em;
      height: 22px;
      width: 22px;
    }
  }

  .btn-link:hover {
    color: #626467 !important;
  }

  .btn-link:focus {
    box-shadow: none;
  }

  .btn-outline-success.disabled,
  .btn-outline-success:disabled {
    color: #009878;
    background-color: transparent;
    cursor: no-drop;
  }
}

.msg-success {
  font-size: 17px;
  color: #009878;
  padding: 1rem 0;
}

.model-body {
  margin: 2rem 2rem 1rem 2rem;
}

.actions-btns {
  text-align: center;
  width: 100%;
  padding-top: 2em;

  .btn {
    width: 8em;
    border-radius: 5px;
    padding: 0.5em;
    background-color: #009878;
    border-color: #009878;
  }
}

.msg-error {
  font-size: 12px;
  color: #ff0000;
}

.ForgotPwd {
  height: 100%;
  .onf-header {
    display: flex;

    img:first-child {
      background: #ffffff;
      padding: 0.3rem;
    }
    img:last-child {
      margin-left: 1rem;
    }
  }

  .forgot-pass-container {
    display: flex;
    font-family: "Mada", sans-serif;

    height: 90%;
    justify-content: center;
    align-items: stretch;
    margin: auto 0;

    .forgot-pass-form {
      background: #ffffff;
      margin: auto 0;
      border: 1px solid #bbb;
      padding: 1.5rem;
      border-radius: 5px;
      height: 525px;
      width: 700px;
      text-align: center;
      box-shadow: 0 0 3em #666060;

      form {
        display: flex;
        flex-direction: column;
      }

      .input-group {
        margin: 2em 0;
        padding: 0 2em;
        width: 100%;
      }

      .input-group-text {
        color: #009878;
      }

      .logo-onf {
        height: 7rem;
        margin: 1rem 0;
      }

      .logo-success {
        width: 5rem;
      }

      .title {
        font-size: 1.4em;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    hr {
      margin-top: 1rem;
      margin-bottom: 1rem;
      width: 100%;
      border: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .message {
      margin: 0.6em 2em;
    }

    .error-container {
      background-color: #fcf7f7;
      border: 1px solid #a62d2d;
      margin-bottom: 5%;
      border-radius: 5px;
      padding: 0.5rem;

      .title {
        color: #a62d2d;
        font-size: 1.5em;
        font-weight: bold;
      }

      p {
        margin: 0 2rem 1rem 1rem;
      }
    }
  }
}
