@import "./../zindex.scss";

.export-modal {
  z-index: $zindexConfirmationModal !important;
}

.modal-dialog {
  .modal-header {
    background: #009878;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0.5rem;
    color: #fff;
  }

  /*** BTN ***/
  .inline-btn-bloc-modal {
    display: flex;
    justify-content: center;
    padding: 2rem;
  }
  .btn-outline-success {
    color: #2f2f2f;
    border: 2px solid #408c7a;
    font-weight: 600;
    padding: 0.5rem 2rem;
    border-radius: 2em;

    svg {
      height: 22px;
      width: 22px;
      margin-right: 0.5rem;
    }
  }

  .btn-outline-success.focus,
  .btn-outline-success:focus {
    box-shadow: 0 0 0 0.2rem rgba(64, 139, 122, 0.4);
  }

  .btn-outline-success:hover {
    background-color: #009878;
    border-color: #009878;
  }

  .btn-link {
    color: #333333;
    font-weight: 500;
    padding-left: 0;
    padding-right: 0;

    svg {
      margin-right: 0.2em;
      height: 22px;
      width: 22px;
    }
  }

  .btn-link:hover {
    color: #626467 !important;
  }

  .btn-link:focus {
    box-shadow: none;
  }

  .btn-outline-success.disabled,
  .btn-outline-success:disabled {
    color: #009878;
    background-color: transparent;
    cursor: no-drop;
  }
}
