.init-pass-container {
  display: flex;
  font-family: "Mada", sans-serif;

  height: 100%;
  justify-content: center;
  align-items: stretch;
  margin: auto 0;

  .init-pass-form {
    background: #ffffff;
    margin: auto 0;
    border: 1px solid #bbb;
    padding: 1.5rem;
    border-radius: 5px;
    min-height: 525px;
    width: 700px;
    text-align: center;
    box-shadow: 0 0 3em #666060;

    .status-error {
      height: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: red;
      font-size: 14px;
    }
    .form-container {
      margin: 2em 0;
      padding: 0 2em;
      width: 100%;
    }

    img {
      height: 7rem;
      margin: 1rem 0;
    }

    .container-rules {
      margin-top: 1.5rem;
      width: 100%;
      .container-left,
      .container-right {
        display: flex;
        text-align: left;
      }

      .container-left {
        justify-content: flex-end;
      }

      .container-right {
        justify-content: flex-start;
      }
    }
  }

  .title {
    font-size: 1.4em;
    font-weight: bold;
    text-transform: uppercase;
  }

  .error-password {
    color: red;
    padding: 1em 0 1em 0;
  }

  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .actions {
    width: 100%;
  }

  .input-group-text {
    color: #009878;
  }

  .input-group {
    margin: 0.5em;
  }
}

.layout {
  justify-content: center;
}
