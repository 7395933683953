.FooterPage {
  height: 100%;
  .footer-header {
    color: #009878;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    padding: 2.5rem 0;
    background: #edede887;
    background: url(../../../assets/svg/bg-top-right.svg), #edede887;
    //background-image: url(../../assets/svg/bg.svg);
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 8rem;
  }

  .footer-content {
    height: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    overflow-y: auto;
    scrollbar-color: #009878 #d7d7cd;

    &::-webkit-scrollbar {
      height: 13px;
      width: 13px;
      background: #d7d7cd;
    }

    &::-webkit-scrollbar-thumb {
      background: #009878;
      border: 3px solid #009878;
    }

    &::-webkit-scrollbar-corner {
      background: #d7d7cd;
    }
  }
}
