@import "./../zindex.scss";

.confirm-modal-block {
  z-index: $zindexConfirmationModal !important;
}

.confirm-modal-backdrop {
  z-index: $zindexConfirmationModalBackdrop !important;
}

.modal-dialog {
  .modal-header {
    background: #009878;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0.5rem;
    color: #fff;
  }

  /*** BTN ***/
  .inline-btn-bloc-modal {
    display: flex;
    justify-content: center;
    padding: 1rem  2rem 2rem 2rem!important; ;
  }

  .btn {
    font-weight: 600;
    padding: 0.5rem 2rem;
    border-radius: 2em;
  }

  .btn-outline-success {
    color: #2f2f2f;
    border: 2px solid #408c7a;
  }

  .btn-outline-success.focus,
  .btn-outline-success:focus {
    box-shadow: 0 0 0 0.2rem rgba(64, 139, 122, 0.4);
  }

  .btn-outline-success:hover {
    background-color: #009878;
    border-color: #009878;
  }

  .btn-link {
    color: #333333;
    font-weight: 500;
    padding-left: 0;
    padding-right: 0;

    svg {
      margin-right: 0.2em;
      height: 22px;
      width: 22px;
    }
  }

  .btn-link:hover {
    color: #626467 !important;
  }

  .btn-link:focus {
    box-shadow: none;
  }

  .btn-outline-success.disabled,
  .btn-outline-success:disabled {
    color: #009878;
    background-color: transparent;
    cursor: no-drop;
  }

  input[type="radio"]:after {
    -moz-appearance: initial;
    content: " ";
    height: 16px;
    width: 16px;
    background-color: #ffffff;
    display: inline-block;
    visibility: visible;
    border: 1px solid #afafaf;
    position: absolute;
    padding-left: 4px;
    margin-top: -2px;
    border-radius: 50px;
  }

  input[type="radio"]:checked:after {
    -moz-appearance: initial;
    background-color: #009878;
    border: 1px solid #009878;
    color: #ffffff;
  }

  .generic-mail {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-items: flex-start;
    align-items: flex-start;
    margin: 1rem 0 1rem 7rem;

    .form-check-input {
      margin-top: 0.1rem !important;
      margin-left: -1.75rem !important;
    }

    .form-check {
      position: relative;
      margin: 0.3rem 2rem;
    }
  }
}

