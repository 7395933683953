#save-modification-button {
  margin-bottom: 15px;
}

.form-control:disabled,
.form-control[readonly] {
  background: #dddddd !important;
}

.change-password-container {
  display: block;
  margin: 30px auto;

  .actions {
    text-align: center;
  }

  .rules {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 60%;
    margin: auto;
  }
}

.change-password-container .password-form-container,
.change-frequence-notification-container .frequence-notification-form-container {
  display: block;
  width: 100%;
}

.change-password-container .message,
.change-frequence-notification-container .message {
  color: #009878;
  font-size: 18px;
  font-weight: bold;
  margin: 30px auto;
}

.change-password-container .password-field {
  float: left;
}

.change-password-container .password-label {
  text-align: right;
  clear: both;
  float: left;
  margin-right: 15px;
}

.horaires-width {
  width: 100%;
}

.radio-label {
  font-weight: bold;
  margin: 1rem 0 1rem 0;
  font-size: 17px;
}

.form-inline {
  margin-bottom: 0.5rem !important;
}

.form-check {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

input[type="radio"] {
  background-color: #ffffff;
  border-radius: 18px;
  border: #bbbbbb solid 1px;
  cursor: pointer;
  display: inline-block;
  height: 26px;
  margin-right: 15px !important;
  position: relative;
  width: 26px;
  -webkit-appearance: none;
}

input[type="radio"]:after {
  background-color: #ffffff;
  border-radius: 25px;
  content: "";
  display: block;
  height: 14px;
  left: 5px;
  position: relative;
  top: 5px;
  width: 14px;
}

input[type="radio"]:checked:after {
  background-color: #4aa28d;
}